<template>
  <div class="holiday-item">
    <HeaderTitle title="Talento"/>
    <div class="d-flex flex-row justify-content-start">
      <img
          src="@/assets/icons/hollidays.svg"
          alt=""
      >
      <div
          v-if="employeeData && employeeData.id !== user.id"
          class="title-text ml-4">
        Vacaciones de {{ employeeData?.name }} {{ employeeData?.lastName }}
      </div>
      <div
          v-if="employeeData && employeeData.id === user.id"
          class="title-text ml-4">
        Mis Vacaciones
      </div>
    </div>
    <el-row class="mt-2">
      <el-card
          class="card-search card-abs w-100 wback"
          un
          dev
          body-style="padding:0"
      >
        <!-- Nueva solicitud de vacaciones -->
        <div class="holiday-item-container mt-0">
          <p class="section-subtitle">Solicitar periodo de vacaciones</p>
          <div class="form-item">
            <div class="col-md-4 col-xl-3">
              <label class="lw-100">Ejercicio</label>
              <el-select
                  filterable
                  clearable
                  v-model="selectedYear"
                  placeholder="Selecciona un ejercicio"
                  @change="filterByExercise"
              >
                <el-option
                    v-for="item in yearOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="col-md-12 d-flex flex-column justify-content-end align-items-end pull-right">
          <button
              style="width: 280px;height: 40px;"
              class="btn btn_yellow btn-search"
              @click="openModalNewHolidays()"
          >
            Nueva solicitud
          </button>
        </div>

      </el-card>
    </el-row>
    <!-- Tabla de días -->
    <div class="table">
      <table :key="selectedYear.id">
        <thead>
        <tr>
          <th class="free-days">
            <div>
              Días libres <span>{{ freeDaysData.total }}</span>
            </div>
          </th>
          <th class="consum-days">
            <div>
              Días consumidos <span>{{ freeDaysData.consumed }}</span>
            </div>
          </th>
          <th class="disp-days">
            <div>
              Días disponibles <span>{{ freeDaysData.availables }}</span>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <!--        <div class="d-flex justify-content-start bg-transparent">-->
        <!--            <button-->
        <!--                style="width: 280px;height: 40px;"-->
        <!--                type="submit"-->
        <!--                class="btn btn_red"-->
        <!--                @click="openModalNewHolidays()"-->
        <!--            >-->
        <!--              Incompatibilidades-->
        <!--            </button>-->
        <!--        </div>-->
        </tbody>
      </table>
    </div>

    <!--    Tabla de incompatibilidades -->
    <div class="d-flex justify-content-start bg-transparent">
      <button
          style="width: 280px;height: 40px;"
          type="submit"
          class="btn btn_red"
          @click="showModalIncompatibilities()"
      >
        Incompatibilidades
      </button>
    </div>


    <!-- Tabla de histórico de solicitudes -->
    <div class="table">
      <table>
        <thead>
        <tr class="bg-transparent">
          <!--<th>Motivo</th>-->
          <th>Inicio</th>
          <th>Fin</th>
          <th>Ejercicio</th>
          <th>Estado</th>
          <th>Comentario</th>
          <th>Días consumidos</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="holiday in employeeHolidays"
            :key="holiday.id"
        >
          <td>
            {{ formatDate(holiday.fromDate) }}
          </td>
          <td>
            {{ formatDate(holiday.toDate) }}
          </td>
          <td>
            {{ holiday.yearExercise }}
          </td>
          <td :class="statusClass(holiday.currentStatus)">
            {{ holiday.currentStatus }}
          </td>
          <td>
            {{ holiday.observations }}
          </td>
          <td class="d-flex justify-content-end denied">
            <b> {{ holiday.daysConsumed }}</b>
          </td>
        </tr>
        <tr v-if="!employeeHolidays || employeeHolidays.length === 0">
          <td
              colspan="6"
              class="text-center">
            No hay vacaciones registradas
          </td>
        </tr>
        </tbody>
      </table>

      <SharedPagination
          v-if="employeeHolidays && totalResults > itemsPerPage"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          @change="page = $event; getEmployeeHolidays(employeeData.id)"
      />
    </div>

    <!-- Modal de crear nuevas vacaciones -->
    <shared-modal
        v-if="showModalNewHolidays"
        :theme="'blue'"
        :size="'medium'"
        @close="closeModalNewHolidays()"
    >
      <template #content>
        <div class="row d-flex flex-column-reverse flex-xl-row">
          <div class="col-12 col-xl-7 mt-5 mt-xl-0">
            <img
                src="../../assets/images/modal_solicitar_vacaciones.png"
                style="max-width: 100%"
                alt="">
          </div>
          <div class="col-12 col-xl-5">
            <el-form
                ref="newHolidayForm"
                class="newHolidayForm"
                :model="newHolidayFormModel"
                name="newHolidayForm"
                status-icon
                @submit.prevent
            >
              <div class="row item-modal text-left align-items-center">
                <!--                <div class="col-sm-3 d-inline-flex align-items-center">-->
                <!--                  <label class="label-holiday-modal">Desde:</label>-->
                <!--                </div>-->
                <div class="col-sm-12">
                  <div class="form-item date-picker">
                    <el-row justify="space-between">
                      <el-col :md="8">
                        <label class="label-holiday-modal">Fecha Inicio:</label>
                      </el-col>
                      <el-col :md="16">
                        <el-date-picker
                            :clearable="false"
                            :picker-options="{firstDayOfWeek: 1}"
                            :prefix-icon="customPrefix"
                            format="DD/MM/YYYY"
                            placeholder="Fecha Inicio"
                            size="large"
                            type="date"
                            v-model="newHolidayFormModel.selectedFromDate"
                            value-format="YYYY-MM-DD"
                        />
                      </el-col>
                      <p
                          v-if="v.selectedFromDate.$error"
                          class="invalid-feedback mb-0">
                        <small>
                          {{ v.selectedFromDate.$errors[0].$message }}
                        </small>
                      </p>
                    </el-row>
                  </div>
                </div>
              </div>
              <div class="row item-modal text-left">
                <div class="col-sm-12">
                  <div class="form-item date-picker">
                    <el-row justify="space-between">
                      <el-col :md="8">
                        <label class="label-holiday-modal mr-2">Fecha Fin:</label>
                      </el-col>
                      <el-col :md="16">
                        <el-date-picker
                            :clearable="false"
                            :default-value="defaultDate"
                            :disabled-date="disabledDate"
                            :picker-options="{ firstDayOfWeek: 1 }"
                            :prefix-icon="customPrefix"
                            format="DD/MM/YYYY"
                            placeholder="Fecha Fin"
                            size="large"
                            type="date"
                            v-model="newHolidayFormModel.selectedToDate"
                            value-format="YYYY-MM-DD"
                        />
                      </el-col>
                      <p
                          v-if="v.selectedToDate.$error"
                          class="invalid-feedback mb-0">
                        <small>
                          {{ v.selectedToDate.$errors[0].$message }}
                        </small>
                      </p>
                    </el-row>
                  </div>
                </div>
              </div>

              <div class="row item-modal text-center">
                <div class="col-auto d-inline-flex align-items-center">
                  <label class="label-holiday-modal">Ejercicio:</label>
                </div>
                <div class="col">
                  <div class="form-item">
                    <el-select
                        v-model="newHolidayFormModel.selectedExerciseInModal"
                    >
                      <el-option
                          v-for="item in yearOptions"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      />
                    </el-select>
                  </div>
                </div>
              </div>

              <div class="row item-modal">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col">
                      <div class="form-item">
                        <textarea
                            class="w-100 p-2"
                            rows="5"
                            v-model="newHolidayFormModel.currentObservations"
                            :placeholder="'Comentario'"
                        />
                      </div>
                      <p
                          v-if="v.currentObservations.$error"
                          class="invalid-feedback mb-0">
                        <small>
                          {{ v.currentObservations.$errors[0].$message }}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row item-modal">
                <div class="col">
                  <button
                      class="btn btn_purple btn__modal"
                      type="button"
                  >
                    VER DIAS DISPONIBLES
                  </button>
                </div>
              </div>
              <div class="row item-modal">
                <div class="col">
                  <button
                      class="btn btn_yellow btn__modal"
                      type="button"
                      @click="requestNewHolidays()"
                  >
                    ENVIAR
                  </button>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </template>
    </shared-modal>
    <el-dialog
        v-model="showIncompatibilities"
        :show-close="false"
    >
      <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5"> Incompatibilidades</span>
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showIncompatibilities=false"
        >
      </div>
      <div
          class="table"
          v-if="incompatibleEmployees.length>0"
      >
        <table>
          <thead>
          <tr>
            <th>Empleado</th>
            <th>Fecha Inicio</th>
            <th>Fecha Fin</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="incompatibleEmployee in incompatibleEmployees"
              :key="incompatibleEmployee.name"
          >
            <td>{{ incompatibleEmployee.name }}</td>
            <td>{{ formatDate(incompatibleEmployee.holidays[0].from) }}</td>
            <td>{{ formatDate(incompatibleEmployee.holidays[0].to) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>No hay incompatibilidades</p>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import SharedModal from "@/components/shared/SharedModal.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";

// Services
import HolidayService from '@/api/services/holiday.service';
import EmployeeService from '@/api/services/employee.service';
import CommonService from '@/api/services/common.service';
import Notification from '@/utils/plugins/NotificationService';

import moment from "moment";
import {getYear} from '@/views/production/mixin/common'
import {useStore} from "vuex";
import {computed, h, onMounted, ref, shallowRef} from "vue";
import {useRoute} from "vue-router";
import {helpers, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})
const statusClass = (status) => {
  if (status === 'Pendiente') {
    return 'pending';
  } else if (status === 'Aprobada') {
    return 'approved';
  } else if (status === 'Rechazada') {
    return 'denied';
  }
}


const params = ref({})
const title = ref("")
const serverTimestamp = ref(null);
const currentServerYear = ref(new Date().getFullYear());
const selectedYear = ref({id: parseInt(currentServerYear.value), name: currentServerYear.value});
const yearOptions = ref([]);
const freeDaysData = ref({total: 0, consumed: 0, availables: 0});
const employeeHolidays = ref([]);
const employeeHolidaysIncompatibilities = ref([]);
const incompatibleEmployees = ref([]);
const totalResults = ref(0)
// eslint-disable-next-line no-unused-vars
const page = ref(1)
// eslint-disable-next-line no-unused-vars
const itemsPerPage = ref(10)
const employeeData = ref(null)
const statusOptions = ref(['Pendiente', 'Aprobada', 'Rechazada'])
const newHolidayFormModel = ref({
  selectedFromDate: null,
  selectedToDate: null,
  selectedExerciseInModal: {id: 0, name: ''},
  currentObservations: ''
})

const showModalNewHolidays = ref(false)
const showIncompatibilities = ref(false)
const defaultDate = ref(null);

const rules = computed(() => {
  return {
    selectedFromDate: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    selectedToDate: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    dateRegister: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    currentObservations: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});
// const calculateDisabledDates = (time) => {
//   if (newHolidayFormModel.value.selectedFromDate) {
//     let Yesterday = new Date(newHolidayFormModel.value.selectedFromDate);
//     Yesterday.setDate(Yesterday.getDate() - 1);
//     return time.getTime() < Yesterday;
//   } else {
//     return false;
//   }
// }

const disabledDate = (time) => {
  if (newHolidayFormModel.value.selectedFromDate) {
    defaultDate.value = newHolidayFormModel.value.selectedFromDate;
    let date = new Date(newHolidayFormModel.value.selectedFromDate);
    return time.getTime() <= date.setDate(date.getDate() - 1);
  } else {
    return time.getTime() <= Date.now();
  }
}

// eslint-disable-next-line no-unused-vars
const newVacationsRequest = ref(true)
const store = useStore();
const route = useRoute();
const user = computed(() => store.state.user);
onMounted(() => {
  if (route.params.id && route.params.id !== 'new') {
    getEmployeeAndHolidaysData(route.params.id);
  } else {
    Notification.addNotification('Identificador del empleado incorrecto', 'error', 6000);
  }
})
const v$ = useVuelidate(rules, newHolidayFormModel);
const v = v$.value;
const filterByExercise = (newValue) => {
  newHolidayFormModel.value.selectedExerciseInModal = newValue;

  freeDaysData.value.consumed = 0;
  freeDaysData.value.availables = 23;

  params.value.yearExercise = selectedYear.value.id

  getEmployeeHolidays(route.params.id);
  getEmployeeHolidayIncompatibilities(route.params.id);
}


/* Obtiene la información del empleado y de sus vacaciones */
const getEmployeeAndHolidaysData = (employeeID) => {
  store.dispatch('setLoading', true)
  CommonService.getServerTimestamp().then((resTimestamp) => {
    serverTimestamp.value = resTimestamp.data.serverTimestamp;

    currentServerYear.value = getYear(serverTimestamp.value);
    // Rellenamos las opciones del desplegable de 'Ejercicio' (popup de solicitar nuevas vacaciones)
    yearOptions.value.push({id: parseInt(currentServerYear.value), name: currentServerYear.value});
    yearOptions.value.push({
      id: parseInt(currentServerYear.value) - 1,
      name: (parseInt(currentServerYear.value) - 1).toString()
    });
    selectedYear.value = parseInt(currentServerYear.value);
    newHolidayFormModel.value.selectedExerciseInModal = selectedYear.value;

    EmployeeService.getEmployee(employeeID).then((res) => {
      employeeData.value = res.data;
      freeDaysData.value.total = employeeData.value.currentDaysOff;
      title.value = (user.value.id === employeeData.value.id) ? 'Mis vacaciones' : 'Vacaciones de ' + employeeData.value.name + ' ' + employeeData.value.lastName;
      getEmployeeHolidays(employeeID);
      getEmployeeHolidayIncompatibilities(employeeID);
      store.dispatch('setLoading', false)
    })
        .catch(() => {
          Notification.addNotification('Error al obtener la información del empleado', 'error', 6000);
          store.dispatch('setLoading', false)
        });
  })
      .catch(() => {
        Notification.addNotification('Error al obtener el timestamp del servidor', 'error', 6000);
        store.dispatch('setLoading', false)
      })
}


/* Obtiene el histórico de vacaciones de un empleado */
const getEmployeeHolidays = (employeeID) => {
  params.value.page = page.value;
  params.value.itemsPerPage = itemsPerPage.value;
  params.value['order[fromDate]'] = 'DESC';
  params.value.yearExercise = selectedYear.value;
  store.dispatch('setLoading', true)
  HolidayService.getEmployeeHolidays(employeeID, params.value).then((res) => {
    employeeHolidays.value = res.data['hydra:member'];
    totalResults.value = res.data['hydra:totalItems'];
    const vacationsDays = employeeHolidays.value.pop()
    freeDaysData.value.total = vacationsDays.vacationDaysPerYear;
    freeDaysData.value.consumed = vacationsDays.consumedDays;
    freeDaysData.value.availables = vacationsDays.availableDays;

    // Procesamos el estado y los días consumidos
    employeeHolidays.value.forEach(holiday => {
      holiday.currentStatus = statusOptions.value[holiday.approved];
    });
    //   freeDaysData.value.availables = freeDaysData.value.total - freeDaysData.value.consumed;
    store.dispatch('setLoading', false)
  })
      .catch(() => {
        Notification.addNotification('Error al obtener la información de las vacaciones del empleado', 'error', 6000);
        store.dispatch('setLoading', false)
      });
}
/**Verificar empleado */
    // eslint-disable-next-line no-unused-vars
const isActiveEmployee = async (id) => {
      return await EmployeeService.getEmployee(id)
          .then(resp => {
            if (!resp.data) return false;
            return resp.data.active;
          })
    }
/* Método para obtener las fechas incompatibles entre empleados */
const getEmployeeHolidayIncompatibilities = async (employeeID) => {
  params.value['employees.holidays.yearExercise'] = selectedYear.value;
  await store.dispatch('setLoading', true)

  HolidayService.getEmployeeIncompatibilities(employeeID, params.value)
      .then(async (res) => {
        employeeHolidaysIncompatibilities.value = res.data;
        employeeHolidaysIncompatibilities.value.forEach(holidayIncompatibility => {
          holidayIncompatibility.employees.forEach(employee => {
            let employeeName = employee.name + ' ' + employee.lastName;
            let holidays = [];
            employee.holidays.forEach(holiday => {
              holiday = {
                from: holiday.fromDate,
                to: holiday.toDate,
              };
              holidays.push(holiday);
            })
            let incompatibleEmployee = {
              name: employeeName,
              holidays: holidays
            };
            incompatibleEmployees.value.push(incompatibleEmployee);
          })
        })
        await store.dispatch('setLoading', false)
      }).catch(() => {
    Notification.addNotification('Error al obtener la información de las vacaciones incompatibles del empleado', 'error', 6000);
    store.dispatch('setLoading', false)
  })
}

/* Método para abrir el modal de 'crear nuevas vacaciones' */
const openModalNewHolidays = () => {
  showModalNewHolidays.value = true;
}


/* Método para cerrar el modal de 'crear nuevas vacaciones' */
const closeModalNewHolidays = () => {
  showModalNewHolidays.value = false;
  v$.value.$reset();
}

const showModalIncompatibilities = () => {
  showIncompatibilities.value = true;
}

/* Solicita un nuevo registro de las vacaciones */
const requestNewHolidays = () => {
  console.log("Request New Holiday", v$.value)
  v$.value.$reset()
  console.log("Reset Request New Holiday", v$.value)
  v$.value.$validate();

  if (v$.value.$errors.length > 0) {
    const item = {
      name: "Vacaciones " + employeeData.value.name + ' ' + employeeData.value.lastName,
      observations: newHolidayFormModel.value.currentObservations,
      fromDate: moment(newHolidayFormModel.value.selectedFromDate).format('YYYY-MM-DD'),
      toDate: moment(newHolidayFormModel.value.selectedToDate).format('YYYY-MM-DD'),
      yearExercise: newHolidayFormModel.value.selectedExerciseInModal.toString(),
      daysConsumed: getBusinessDatesCount(new Date(newHolidayFormModel.value.selectedFromDate), new Date(newHolidayFormModel.value.selectedToDate)),
      employee: employeeData.value['@id']
    }
    if (typeof employeeData.value.teamManager !== "undefined" && employeeData.value.teamManager && employeeData.value.teamManager.id) {
      item.approverEmployeeId = employeeData.value.teamManager.id;
    }
    store.dispatch('setLoading', true)
    // Enviamos la solicitud...
    HolidayService.createHoliday(item)
        .then(() => {
          Notification.addNotification('Solicitud de vacaciones enviada', 'success', 6000);
          getEmployeeHolidays(employeeData.value.id);
          newHolidayFormModel.value.selectedFromDate = null;
          newHolidayFormModel.value.selectedToDate = null;
          newHolidayFormModel.value.currentObservations = '';
          store.dispatch('setLoading', false)
        })
        .catch(() => {
          Notification.addNotification('Error al obtener el listado de vacaciones', 'error', 6000);
          store.dispatch('setLoading', false)
        });

    closeModalNewHolidays();
  } else {
    Notification.addNotification('Hay campos con valores incorrectos', 'error', 6000);
  }
}


/*
 * Obtiene los días laborables entre dos fechas (no contabiliza sábados y domíngos)
 * NOTA: de momento no tiene en cuenta los días festivos
 */
const getBusinessDatesCount = (startDate, endDate) => {
  let count = 0;
  let curDate = startDate;
  while (curDate <= endDate) {
    let dayOfWeek = curDate.getDay();
    if (!((dayOfWeek === 6) || (dayOfWeek === 0)))
      count++;
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
}


const formatDate = (date) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY');
  }
  return '';
}
// eslint-disable-next-line no-unused-vars
const formatDateForNewHolidayRequest = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  }
  return '';
}

</script>

<style
    lang="scss"
    scoped>
.table table th {
  text-align: left;
  padding-bottom: 0;
}

.approved, .denied, .pending {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}

.approved {
  color: #474CE6;
}

.denied {
  color: #F9156F;
}

.pending {
  color: #F7D20E;
}

.section-subtitle {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #000000;

}

.card-search {
  border-radius: 15px;
  max-height: 280px;
  background: white url("../../assets/images/camera.png") no-repeat;
  background-position-x: 100%;
  background-size: contain;
  overflow: visible !important;
}

.holiday-item {
  margin-bottom: 20px;
}

.backButton {
  padding-left: 0px;
  display: initial;
}

.holiday-item-container {
  margin-top: 35px;
}

.free-days, .consum-days, .disp-days {
  align-items: center;
  text-align: left;

  div {
    justify-content: space-between !important;
    display: flex;
    margin-bottom: .5em;
  }

  span {
    font-size: 20px;
    font-weight: bold;
  }
}

.free-days {
  background-color: #F7D20E !important;
  color: #474CE6;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.consum-days {
  background-color: #F9156F;
  color: white;
}

.disp-days {
  background-color: #474CE6;
  color: white;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.modal-employee-label {
  font-size: 20px;
  text-align: center;
  display: block;
  font-weight: bold;;
}

.item-modal {
  margin-top: 5px;
}

.form-item {
  margin-bottom: 5px !important;
}

.label-holiday-modal {
  position: relative;
  bottom: 5px;
}

.pagination {
  padding: 0px 0px 20px 0px;
}

.help-icon {
  margin-left: 10px;
  color: #17A2B8;
}

.error-validation {
  border: 2px solid #F56C6C;
}

.btn__modal {
  margin-top: 1.5em;
  height: 4em;
  border-radius: 30px;
}

.date-picker input.el-input__inner, .date-picker input.el-input__inner:focus {
  background-color: white !important;
}

#asterisk {
  position: absolute;
  vertical-align: super;
  height: auto;
  width: 2.2em;
  fill: #f9156f;
}

.el-dialog__header {
  padding: 0;
}

.my-header {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-header {
  text-align: left;
  font-size: 4em;
  font-weight: 900;
  letter-spacing: -0.05em;
  color: #f9156f;
}

//input,
//  input[type=text],
//  input[type=text]:focus,
//  input[type=text]:active {
//      border: none;
//      border-radius: 0px;
//      border-bottom: 1px solid white;
//      background-color: $bg-blue;
//      color: white;
//
//  }
//
//  .datepicker {
//    input.el-input__inner {
//      border: none;
//      border-radius: 0px;
//      border-bottom: 1px solid white;
//      background-color: $bg-blue;
//      color: white;
//    }
//  }

//div {
//  .el-form-item {
//    background-color: green;
//    div.el-form-item__content {
//      background-color: red;
//    }
//
//  }
//}
//
//.el-form-item {
//  .el-form-item__content {
//    .el-date-editor {
//      input.el-input__inner {
//        background-color: blue !important;
//      }
//    }
//  }
//}

.el-date-editor {
  width: 100%;
}

//.el-date-editor {
//  width: 300px;
//  &.el-input {
//    //background-color: red;
//  }
//  input.el-input__inner {
//    background-color: $bg-blue;
//  }
//}

//div.el-date-editor {
//input.el-input__inner {
//  background-color: $bg-blue;
//}
//}


</style>
