import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';


const RESOURCE_NAME = '/api/projects';
const RESOURCE_NAME_CLIENT = '/api/clients';
const RESOURCE_NAME_TYPES= '/api/project_types';
const RESOURCE_SYNC_PROJECTS = '/api/clockify/projects/sync';

export default {

    async getClients(){
        let url = "?order[name]=ASC";

        return Api()
        .get(RESOURCE_NAME_CLIENT+url,{params:{page:1,itemsPerPage:1000}})
        .catch((err) => errorHandler(err, null));
    },

    async getTypes(){
        return Api()
        .get(RESOURCE_NAME_TYPES)
        .catch((err) => errorHandler(err, null));
    },

    _createSearchUrl(name,client,type){

        // if(!name && !client && !type) return "";
        let url = "?order[initialDate]=DESC"

        if(name) url+=`&name=${name}`;
        if(client) url+=`&client.name=${client}`;
        if(type) url+=`&projectType.name=${type}`;
        return url;
    },

    async getProjects(params){
        let url = "?order[initialDate]=DESC"
        return Api()
        .get(RESOURCE_NAME+url,{params})
        .catch((err) => errorHandler(err, null));
    },

    async getProject(id){


        return Api()
        .get(RESOURCE_NAME+'/'+id)
        .catch((err) => errorHandler(err, null));
    },
    async udpdateObservations(item, id) {
        return Api().put(RESOURCE_NAME + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },

    async syncClockifyProjects() {
        return Api().get(RESOURCE_SYNC_PROJECTS)
            .catch((err) => errorHandler(err, null));
    }





}
