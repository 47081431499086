<template>
  <div id="home">
    <Header
        title="Hoy es"
        :show-card="true"
    />
    <div id="body-content">
      <svg
          id="bell"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
      >
        <path
            d="m453.9 96.3c-10.5 10.5-27.6 10.5-38.2 0-10.5-10.5-10.5-27.6 0-38.2 10.5-10.5 27.6-10.5 38.2 0 10.5 10.6 10.5 27.7 0 38.2zm-40.2 136.1c-14.2 35.9-44.3 68.9-71.8 96.4-31.5 31.5-42.4 72.4-45.5 104.3l-217.6-217.6c31.9-3.1 72.8-14 104.3-45.5 27.5-27.5 60.5-57.6 96.4-71.8 40.7-16.1 75.2-8 108.7 25.5 33.5 33.5 41.6 68 25.5 108.7zm-288.9 154.8c-21.4-21.4-24.5-54.4-9-79l88.1 88.1c-24.8 15.4-57.7 12.4-79.1-9.1zm356.8-356.8c-25.9-25.9-67.8-25.9-93.6 0-9.2 9.2-15.1 20.5-17.8 32.3-10-4.7-20.3-8-30.8-9.9-23.8-4.3-48.8-1.3-74.3 8.7-42.6 16.8-79.4 50.2-109.8 80.6-45 45-120.8 33.9-121.5 33.7-8.4-1.4-16.8 2.9-20.7 10.5-3.9 7.6-2.4 16.9 3.6 22.9l70.5 70.5c-30.6 40.5-27.5 98.5 9.5 135.4 36.9 36.9 94.9 40.1 135.4 9.5l70.5 70.5c6 6 15.3 7.5 22.9 3.6 7.6-3.9 11.9-12.2 10.5-20.7 0-0.2-2.9-19.4-0.1-44.1 3.7-32.6 15.1-58.7 33.8-77.4 30.4-30.4 63.8-67.2 80.6-109.8 10.1-25.5 13-50.5 8.7-74.3-1.9-10.5-5.2-20.8-9.9-30.8 11.9-2.6 23.1-8.6 32.3-17.8 26.1-25.6 26.1-67.5 0.2-93.4v0z"
        />
      </svg>
      <span id="title">Avisos</span>

      <div class="home-cards-container">
        <el-row :gap="10">
          <el-card
              class="box-card"
              body-style="padding:10px;"
          >
            <div class="d-flex align-items-center justify-content-start">
              <div class="card-icon d-flex justify-content-start align-items-center">
                <svg
                    id="islands"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 372 372"
                >
                  <path
                      d="M186 0C83.4 0 0 83.4 0 186s83.4 186 186 186 186-83.4 186-186S288.6 0 186 0zM186 206.6c36.4 0 66.7 27.1 71.5 62.2H114.5C119.3 233.7 149.6 206.6 186 206.6zM266.3 233.4c-2.1-3.6-4.4-7.1-6.9-10.4h88.4c-0.8 3.4-1.7 6.8-2.7 10.2h-76.9C267.6 233.3 266.9 233.3 266.3 233.4zM186 20c91.5 0 166 74.5 166 166 0 5.8-0.3 11.4-0.9 17h-112.6C223.6 192.7 205.5 186.6 186 186.6c-4.4 0-8.7 0.3-12.9 0.9 -7.9-22.4-2.4-47 14.1-63.9 3.3 0.6 6.4 2.2 9 4.6 3.5 3.3 5.4 7.8 5.5 12.5 0.1 5.4 4.6 9.8 10 9.8 0.1 0 0.2 0 0.2 0 5.5-0.1 9.9-4.7 9.8-10.2 -0.2-10.1-4.4-19.5-11.7-26.5 -2.3-2.2-4.8-4.1-7.6-5.6 10.5-8.4 25.6-9.3 37.2-1.3 4.5 3.1 10.8 2 13.9-2.5 3.1-4.5 2-10.8-2.5-13.9 -19.1-13.2-44.2-11.6-61.4 2.4 -8.8-21.9-31.4-36.1-55.8-33.1 -5.5 0.7-9.4 5.6-8.7 11.1 0.7 5.5 5.6 9.4 11.1 8.7 13-1.6 25.1 4.6 31.8 14.9 -12.4-2.6-25.9 1-35.3 10.9 -3.8 4-3.7 10.3 0.3 14.1 1.9 1.8 4.4 2.8 6.9 2.8 2.6 0 5.3-1 7.2-3.1 5.5-5.8 13.9-7 20.8-3.8 -7.5 9.3-13 20.3-15.9 32 -3.7 14.9-3 30.7 1.7 45.2 -7.2 2.7-14 6.3-20.2 10.6H20.9C20.3 197.4 20 191.8 20 186 20 94.5 94.5 20 186 20zM186 352c-67.6 0-125.9-40.6-151.8-98.7h33.6c5.5 0 10-4.5 10-10s-4.5-10-10-10H26.9c-1-3.4-1.9-6.8-2.7-10.2h88.4c-11.8 15.5-18.8 34.8-18.8 55.8 0 5.5 4.5 10 10 10h164.5c5.5 0 10-4.5 10-10 0-8.9-1.3-17.4-3.6-25.5h63.1C311.9 311.4 253.6 352 186 352z"
                  />
                </svg>
                <span class="card-title">VACACIONES</span>
              </div>
              <div class="card-text">
                <ul>
                  <li v-if="restHoliday && restHoliday > 0">
                    {{ restHoliday > 1 ? 'TE FALTAN' : 'TE FALTA' }} {{ restHoliday }}
                    {{ restHoliday > 1 ? 'DIAS' : 'DIA' }} PARA TUS VACACIONES
                    <!--                    TE FALTAN {{ restHoliday }} DIAS PARA TUS VACACIONES-->
                  </li>
                  <!--                  <li v-if="restHoliday === 0">-->
                  <!--                    {{ selfHoliday }}-->
                  <!--                  </li>-->
                  <li v-if="daysLeftOnVacation && daysLeftOnVacation >= 0">
                    {{ daysLeftOnVacation > 1 ? 'TE QUEDAN' : 'TE QUEDA' }} {{ daysLeftOnVacation }}
                    {{ daysLeftOnVacation > 1 ? 'DIAS' : 'DIA' }} DE VACACIONES
                    <!--                    TE QUEDAN {{ daysLeftOnVacation }} DIAS DE VACACIONES-->
                  </li>
                  <li v-if="holiday && holiday.holidaysToday && Array.isArray(holiday.holidaysToday) && holiday.holidaysToday.length>0">
                    <span>
                      {{ firstHolidays(holiday.holidaysToday) }}
                      {{ holiday.holidaysToday.length > 1 ? 'ESTÁN' : 'ESTÁ' }} DE VACACIONES
                      <span v-if="holiday.holidaysToday.length > 3">
                        ...y otros compañeros, pulsa el + para ver el detalle
                      </span>
                    </span>
                  </li>
                  <li v-if="holiday && holiday.holidaysOn7Days && holiday.holidaysOn7Days.length > 0">
                    <!--                  <li v-if="nextHoliday!==''">-->
                    EN LOS PRÓXIMOS 7 DIAS
                    <!--                    {{ toUpper(nextHoliday) }}-->
                    <!--                    {{ holiday.holidaysOn7Days.length > 1 ? 'ESTARÁN' : 'ESTARÁ' }} DE VACACIONES-->
                    <span>
                      {{ firstNextHolidays(nextHoliday) }}
                      {{ holiday.holidaysOn7Days.length > 1 ? 'ESTARÁN' : 'ESTARÁ' }} DE VACACIONES
                    <span v-if="holiday.holidaysOn7Days.length > 3">
                      ...y otros compañeros, pulsa el + para ver el detalle
                    </span>
                    </span>

                  </li>
                  <li
                      v-if="holiday && holiday.holidaysOn7Days && holiday.employeeHolidays && holiday.holidaysToday
                  && holiday.holidaysOn7Days.length===0 && holiday.employeeHolidays.length===0 && holiday.holidaysToday.length===0">
                    NO HAY COMPAÑEROS DE VACACIONES
                  </li>
                </ul>
              </div>
              <div class="ml-auto d-flex justify-content-end align-items-center px-4">
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="chronometer"
                  viewBox="0 0 233.8 233.8"
                >
                  <path
                    d="M188.2 79.4l6.1-6.1 2.9 2.9c1.5 1.5 3.4 2.2 5.3 2.2 1.9 0 3.8-0.7 5.3-2.2 2.9-2.9 2.9-7.7 0-10.6l-16.4-16.4c-2.9-2.9-7.7-2.9-10.6 0 -2.9 2.9-2.9 7.7 0 10.6l2.9 2.9 -6.1 6.1c-10.4-8.9-22.9-15.6-36.5-19.2 4.1-5.2 6.5-11.8 6.5-18.9C147.6 13.8 133.8 0 116.9 0c-16.9 0-30.7 13.8-30.7 30.7 0 7.1 2.4 13.7 6.5 18.9 -13.6 3.6-26 10.3-36.5 19.2l-6.1-6.1 2.9-2.9c2.9-2.9 2.9-7.7 0-10.6 -2.9-2.9-7.7-2.9-10.6 0L26 65.7c-2.9 2.9-2.9 7.7 0 10.6 1.5 1.5 3.4 2.2 5.3 2.2s3.8-0.7 5.3-2.2l2.9-2.9 6.1 6.1c-13.9 16.4-22.4 37.6-22.4 60.7 0 51.6 42 93.7 93.7 93.7s93.7-42 93.7-93.7C210.5 117 202.1 95.8 188.2 79.4zM101.2 30.7c0-8.7 7.1-15.7 15.7-15.7s15.7 7.1 15.7 15.7 -7.1 15.7-15.7 15.7S101.2 39.4 101.2 30.7zM116.9 218.8c-43.4 0-78.7-35.3-78.7-78.7 0-43.4 35.3-78.7 78.7-78.7 43.4 0 78.7 35.3 78.7 78.7C195.5 183.5 160.3 218.8 116.9 218.8z"
                  />
                  <path
                    d="M116.9 77c-4.1 0-7.5 3.4-7.5 7.5v55.6c0 4.1 3.4 7.5 7.5 7.5 4.1 0 7.5-3.4 7.5-7.5V84.5C124.4 80.3 121 77 116.9 77z"
                  />
                </svg> -->
                <!-- <span class="time mx-2">{{ now | formatTime }}</span> -->
                <shared-button-plus @onClick="showModalHolidays()"/>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card
              class="box-card"
              body-style="padding:10px;"
          >
            <div class="d-flex align-items-center justify-content-start">
              <div class="card-icon d-flex justify-content-start align-items-center">
                <svg
                    id="islands"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 595.3 841.9"
                    style="enable-background: new 0 0 595.3 841.9"
                    xml:space="preserve"
                >
                  <path
                      d="M42,614.9c0-84.7,0-169.4,0-254c2.6-6.6,7.8-9.8,14.1-12.2c72-27.8,143.9-55.8,215.8-83.7c2.9-1.1,4.5-2.4,5.2-5.7
                  c8.2-38.6,52.9-56,85.1-33.2c2.4,1.7,4.4,2.2,7.2,1c6.1-2.6,12.5-4.6,18.6-7.3c5.7-2.5,11.4-3,17.5-2.6
                  c32.3,1.7,61.6,11.7,87.6,31.1c26.2,19.6,44.9,44.9,54.2,76.5c3.2,10.8,4.5,22.1,6.7,33.1c0,85.7,0,171.4,0,257
                  c-3.3,8.9-9.9,11.9-19.1,11.9c-157.9-0.1-315.9-0.1-473.8,0C51.9,626.8,45.3,623.8,42,614.9z M523.9,457.7c-1.6,0.8-2.6,1.2-3.7,1.7
                  c-40.2,18.1-78.9,15.5-115.8-8.9c-3.5-2.3-5.3-1-7.8,0.8c-38.4,26.3-90.5,26.3-128.8-0.1c-3.3-2.3-5.4-2.3-8.7,0
                  c-17.8,12.4-37.7,18.8-59.4,19.7c-25.8,1.1-49.4-5.6-70.6-20.4c-2.6-1.8-4.2-1.5-6.6,0.1c-3.8,2.6-7.7,5.5-11.9,7.2
                  c-12.4,4.9-25.1,9.3-38.2,14.1c0,40.5,0,82.4,0,124.4c150.7,0,301.1,0,451.6,0C523.9,550.1,523.9,504.3,523.9,457.7z M72.2,439.5
                  c0.8-0.1,1.4-0.1,2.1-0.3c15.6-3.1,29-10.4,40.8-21c4.9-4.4,10.5-6,16.6-3.2c2.8,1.3,5.3,3.4,7.7,5.4c24.2,20.1,51.4,25.3,81.4,16.5
                  c11.7-3.4,21.8-9.7,30.9-17.8c8.4-7.5,15-7.4,23.5-0.2c1,0.9,2,1.8,3,2.6c21.1,17.2,45.1,22.8,71.6,17.7c15.4-3,28.7-10.3,40.3-20.8
                  c7.2-6.5,14.6-6.3,22,0c1.3,1.1,2.5,2.2,3.7,3.3c29.5,25.1,75.7,25.9,105.8,1.7c1.1-0.9,2.4-2.2,2.4-3.4c0.2-12.6,0.1-25.2,0.1-37.8
                  c-150.9,0-301.3,0-451.8,0C72.2,401.4,72.2,420.4,72.2,439.5z M133.3,350.9c0.1,0.2,0.1,0.4,0.2,0.6c129.7,0,259.4,0,390.6,0
                  c-4-11.6-6.5-22.9-11.5-33.1c-20.1-40.7-53.4-63.3-97.9-70.5c-11.7-1.9-21.8,1.5-31.7,6.6c8.4,33.5-11.4,57.6-30.3,65.5
                  c-11.9,4.9-24.1,6.3-36.6,2.9c-15.9-4.4-27.5-14.3-35.2-29.2C231.5,312.8,182.4,331.9,133.3,350.9z M305.9,270.5
                  c0.1,13.5,11,24.4,24.5,24.3c13.7-0.1,24.7-11.3,24.6-24.9c-0.1-13.3-10.9-24-24.2-24C317.1,245.8,305.9,257,305.9,270.5z"
                  />
                </svg>

                <span class="card-title">CUMPLEAÑOS</span>
              </div>
              <div class="card-text">
                <ul>
                  <li v-if="birthDays.today && birthDays.today.length>0">
                    {{ toUpper(birthDays.today) }}
                  </li>
                  <li v-if="birthDays.tomorrow && birthDays.tomorrow.length>0">
                    {{ toUpper(birthDays.tomorrow) }}
                  </li>
                  <li v-if="birthDays.next && birthDays.next.length>0">
                    {{ toUpper(birthDays.next) }}
                  </li>
                </ul>
              </div>
              <div class="ml-auto d-flex align-items-center justify-content-end px-4">
                <!--  <svg
                   xmlns="http://www.w3.org/2000/svg"
                   id="chronometer"
                   viewBox="0 0 233.8 233.8"
                 >
                   <path
                     d="M188.2 79.4l6.1-6.1 2.9 2.9c1.5 1.5 3.4 2.2 5.3 2.2 1.9 0 3.8-0.7 5.3-2.2 2.9-2.9 2.9-7.7 0-10.6l-16.4-16.4c-2.9-2.9-7.7-2.9-10.6 0 -2.9 2.9-2.9 7.7 0 10.6l2.9 2.9 -6.1 6.1c-10.4-8.9-22.9-15.6-36.5-19.2 4.1-5.2 6.5-11.8 6.5-18.9C147.6 13.8 133.8 0 116.9 0c-16.9 0-30.7 13.8-30.7 30.7 0 7.1 2.4 13.7 6.5 18.9 -13.6 3.6-26 10.3-36.5 19.2l-6.1-6.1 2.9-2.9c2.9-2.9 2.9-7.7 0-10.6 -2.9-2.9-7.7-2.9-10.6 0L26 65.7c-2.9 2.9-2.9 7.7 0 10.6 1.5 1.5 3.4 2.2 5.3 2.2s3.8-0.7 5.3-2.2l2.9-2.9 6.1 6.1c-13.9 16.4-22.4 37.6-22.4 60.7 0 51.6 42 93.7 93.7 93.7s93.7-42 93.7-93.7C210.5 117 202.1 95.8 188.2 79.4zM101.2 30.7c0-8.7 7.1-15.7 15.7-15.7s15.7 7.1 15.7 15.7 -7.1 15.7-15.7 15.7S101.2 39.4 101.2 30.7zM116.9 218.8c-43.4 0-78.7-35.3-78.7-78.7 0-43.4 35.3-78.7 78.7-78.7 43.4 0 78.7 35.3 78.7 78.7C195.5 183.5 160.3 218.8 116.9 218.8z"
                   />
                   <path
                     d="M116.9 77c-4.1 0-7.5 3.4-7.5 7.5v55.6c0 4.1 3.4 7.5 7.5 7.5 4.1 0 7.5-3.4 7.5-7.5V84.5C124.4 80.3 121 77 116.9 77z"
                   />
                 </svg> -->
                <!-- <span class="time mx-2 text-muted">{{ now | formatTime }}</span> -->
                <shared-button-plus @onClick="showDialogHappyverde=true"/>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card
              class="box-card"
              body-style="padding:10px;"
          >
            <div class="d-flex align-items-center justify-content-start">
              <div class="card-icon d-flex justify-content-start align-items-center">
                <svg
                    id="islands"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 595.3 841.9"
                    style="enable-background:new 0 0 595.3 841.9;"
                    xml:space="preserve"
                >
                  <path
                      d="M308,164.9c6.9,0.9,13.9,1.6,20.7,2.8c60.8,10.5,106.8,42.9,136.5,96.6c26.1,47.2,31.3,97.4,13.2,149
                      c-9.3,26.4-23.7,50.3-40.1,72.8c-45.9,62.8-92,125.4-138.1,188.1c-0.5,0.7-1.1,1.3-1.9,2.2c-1-1.1-1.9-2-2.6-3
                      c-45.2-61.7-90.5-123.3-135.5-185.1c-16.9-23.2-31.8-47.7-41.7-74.8c-13.4-36.5-13.5-73.4-3-110.4c11.3-39.7,33.5-72.2,65.9-97.7
                      c24-18.9,51-31.2,81-36.9c8.8-1.7,17.8-2.4,26.7-3.6C295.3,164.9,301.7,164.9,308,164.9z M298.4,626.5c1.3-1.6,2.3-2.9,3.3-4.2
                      c30.3-41.4,60.6-82.8,90.9-124.2c17.2-23.5,34.7-46.8,48.1-72.9c16.6-32.2,22.2-66,15-101.7C442.8,259.3,391,208.9,326,197.5
                      c-32.4-5.7-63.7-1.8-93.6,11.8c-37.3,17.1-64.4,44.5-80.9,82c-12.7,29-17,59.4-10.7,90.8c5.4,27.3,17.8,51.7,33.7,74
                      c37.5,52.4,75.7,104.3,113.6,156.4C291.4,617.1,294.8,621.6,298.4,626.5z"
                  />
                  <path
                      d="M298.5,220.8c12.2,24.8,24,48.6,35.7,72.5c1.4,2.9,3.1,4.4,6.6,4.8c25.1,3.4,50.1,7.2,75.1,10.8c1.3,0.2,2.6,0.5,3.9,0.8
                      c0.5,0.5,0.9,1,1.4,1.5c-1.5,0.9-3.2,1.6-4.4,2.8c-17.9,17.4-35.8,34.9-53.8,52.3c-2.4,2.4-3.1,4.5-2.5,7.9
                      c4.6,26.2,9.1,52.5,13.7,79.6c-2.1-1-3.7-1.8-5.2-2.5c-22.1-11.6-44.2-23.1-66.3-34.9c-3.1-1.7-5.5-1.7-8.7,0
                      c-21.9,11.7-43.9,23.2-65.8,34.7c-1.6,0.8-3.2,1.6-5.6,2.8c1.7-10,3.2-19.2,4.8-28.3c3-17.7,6.2-35.4,9-53.2
                      c0.3-1.8-1.1-4.3-2.6-5.7c-17.7-17.4-35.5-34.7-53.3-52c-1.3-1.3-2.8-2.4-5.3-4.6c7.4-1.2,13.3-2.2,19.2-3c20.4-3,40.8-6.1,61.3-8.8
                      c3.7-0.5,5.5-2,7-5.1c10.9-22.4,22-44.8,33.1-67.2C296.5,224.4,297.3,223,298.5,220.8z M298.4,288.5c-4.3,8.8-9.1,16.3-11.9,24.6
                      c-3.4,10-9.8,13.6-19.8,14c-8.5,0.3-16.9,2.3-25.1,3.5c6.9,7.3,13.1,14.8,20.4,21c6.1,5.2,7.7,10.4,5.7,18.1
                      c-2.4,9.3-3.4,18.9-5.1,29.4c8.1-4.3,15.8-7.4,22.4-11.9c9.3-6.5,17.6-6.6,27,0c6.7,4.6,14.4,7.8,22.6,12c-2.1-12.4-4-23.7-6.1-34.9
                      c-0.7-3.6,0.1-6.1,2.8-8.8c8.1-8.2,15.7-16.7,23.2-24.8c0.1,0-1.1-0.4-2.4-0.6c-10-1.4-20.1-3-30.2-4.2c-3.8-0.5-6.1-2-7.8-5.6
                      C309.1,309.8,303.9,299.6,298.4,288.5z"
                  />
                </svg>
                <span class="card-title">FESTIVOS</span>
              </div>
              <div class="card-text">
                <ul>
                  <li v-if="festives">
                    <div
                        v-for="(festive, index) in festives"
                        :key="index"
                    >
                      {{ toUpper(festive) }}
                      <br v-if="index !== festives.length -1">
                    </div>
                  </li>
                </ul>
              </div>
              <div class="ml-auto d-flex justify-content-end align-items-center px-4">
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="chronometer"
                  viewBox="0 0 233.8 233.8"
                >
                  <path
                    d="M188.2 79.4l6.1-6.1 2.9 2.9c1.5 1.5 3.4 2.2 5.3 2.2 1.9 0 3.8-0.7 5.3-2.2 2.9-2.9 2.9-7.7 0-10.6l-16.4-16.4c-2.9-2.9-7.7-2.9-10.6 0 -2.9 2.9-2.9 7.7 0 10.6l2.9 2.9 -6.1 6.1c-10.4-8.9-22.9-15.6-36.5-19.2 4.1-5.2 6.5-11.8 6.5-18.9C147.6 13.8 133.8 0 116.9 0c-16.9 0-30.7 13.8-30.7 30.7 0 7.1 2.4 13.7 6.5 18.9 -13.6 3.6-26 10.3-36.5 19.2l-6.1-6.1 2.9-2.9c2.9-2.9 2.9-7.7 0-10.6 -2.9-2.9-7.7-2.9-10.6 0L26 65.7c-2.9 2.9-2.9 7.7 0 10.6 1.5 1.5 3.4 2.2 5.3 2.2s3.8-0.7 5.3-2.2l2.9-2.9 6.1 6.1c-13.9 16.4-22.4 37.6-22.4 60.7 0 51.6 42 93.7 93.7 93.7s93.7-42 93.7-93.7C210.5 117 202.1 95.8 188.2 79.4zM101.2 30.7c0-8.7 7.1-15.7 15.7-15.7s15.7 7.1 15.7 15.7 -7.1 15.7-15.7 15.7S101.2 39.4 101.2 30.7zM116.9 218.8c-43.4 0-78.7-35.3-78.7-78.7 0-43.4 35.3-78.7 78.7-78.7 43.4 0 78.7 35.3 78.7 78.7C195.5 183.5 160.3 218.8 116.9 218.8z"
                  />
                  <path
                    d="M116.9 77c-4.1 0-7.5 3.4-7.5 7.5v55.6c0 4.1 3.4 7.5 7.5 7.5 4.1 0 7.5-3.4 7.5-7.5V84.5C124.4 80.3 121 77 116.9 77z"
                  />
                </svg> -->
                <!--  <span class="time mx-2">{{ now | formatTime }}</span> -->
                <shared-button-plus @onClick="gotToCalendar()"/>
              </div>
            </div>
          </el-card>
        </el-row>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <shared-card-undanews title="Undanews"/>
      </div>
      <div class="col-md-6">
        <shared-card-undanitas title="Undanitas"/>
      </div>
    </div>
    <el-dialog
        v-model="showDialogHoliday"
        :show-close="false"
    >
      <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5"> Vacaciones</span>
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showDialogHoliday=false"
        >
      </div>
      <employees-with-holidays-new :holidays="holiday"/>
    </el-dialog>
    <el-dialog
        v-model="showDialogHappyverde"
        :show-close="false"
    >
      <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5 mt-3"> Cumpleaños</span>
        <img
            class="inter-icon"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
        >
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showDialogHappyverde=false"
        >
      </div>
      <employees-with-hapy-verde :happy-verde="happyVerdes"/>
    </el-dialog>
    <el-dialog
        v-model="store.state.showDialogUndanews"
        :width="980"
        :height="800"
        styles="border-radius:10px;overflow-y:auto;"
    >
      <UndanewsCDialog/>
    </el-dialog>
    <el-dialog
        v-model="store.state.showDialogUndanita"
        :width="980"
        :height="800"
        styles="border-radius:10px;overflow-y:auto;"
    >
      <UndanitasCDialog/>
    </el-dialog>
  </div>
</template>

<script setup>
import Header from "@/components/layout/Header.vue";
import SharedCardUndanews from "@/components/shared/SharedCardUndanews.vue";
import SharedCardUndanitas from "../components/shared/SharedCardUndanitas.vue";
import SharedButtonPlus from "../components/shared/SharedButtonPlus.vue";
import HolidayService from "@/api/services/holiday.service";
import moment from "moment";
import EmployeesWithHolidaysNew from './production/components/EmployeesWithHolidaysNew.vue';
import EmployeesWithHapyVerde from './production/components/EmployeesWithHapyVerde.vue';
import UndanewsCDialog from "@/components/shared/UndanewsCDialog.vue";
import UndanitasCDialog from "@/components/shared/UndanitasCDialog.vue";
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import common from "@/api/services/common.service";

const {getServerTimestamp} = common;
const store = useStore();
const router = useRouter();
// eslint-disable-next-line no-unused-vars
const showFullHolidays = ref(false);
const remitter = ref("");
const happy_message = ref("Que tengas un feliz día en tu cumpleaños");
const showDialogHoliday = ref(false);
const showDialogHappyverde = ref(false);
const restHoliday = ref(null);
const daysLeftUntilHolidays = ref(null);
const daysLeftOnVacation = ref(null);
const nextHoliday = ref("");
const holiday = ref({});
// eslint-disable-next-line no-unused-vars
const undanitas = ref([]);
const happyVerdes = ref([]);
// eslint-disable-next-line no-unused-vars
const festive = ref({
  nationals: "",
  local: [],
});
const festives = ref([]);
const birthDays = ref({
  today: "",
  tomorrow: "",
  next: "",
});
const now = ref("");
// eslint-disable-next-line no-unused-vars
const interval = ref(null);

// eslint-disable-next-line no-unused-vars
const user = computed(() => store.state.user);
// eslint-disable-next-line no-unused-vars
const selfHoliday = computed(() => (restHoliday.value === 0 ? " ESTÁS DE VACACIONES" : ""));

watch(now, (nval) => {
  if (nval) {
    getNextBirthDays();
    getNextHoliday();
    getNextPublicHolidays();
  }
});


onMounted(async () => {
  await getServerTime();
  await store.dispatch("setShowDialogUndanews", false);
  await store.dispatch("setShowDialogUndanita", false);
  remitter.value = user.value.name;
})

// eslint-disable-next-line no-unused-vars
const formatTime = (value) => {
  if (value) {
    return moment(value).format("hh:mm A");
  }
  return "";
}
const toUpper = (value) => {
  if (!value || typeof value !== 'string') return '';
  return value.toUpperCase();
}
const firstHolidays = (items) => {
  let values = []
  if (items.length < 3) {

    items.map((item) => {
      let text = item.name + ' ' + item.lastname
      values.push(text.toUpperCase())
    })
    return values.join(', ')
  }

  items.map((item, index) => {
    if (index < 3) {
      let text = item.name + ' ' + item.lastname
      values.push(text.toUpperCase())
    }
  })
  return values.join(', ')

}
const firstNextHolidays = (items) => {
  // console.log('firstNextHolidays', items)
  let values = []
  typeof items === 'string' ? items = items.split(',') : items
  if (items.length < 3) {
    items.map((item) => {
      values.push(item.toUpperCase())
    })
    return values.join(', ');
  }

  items.map((item, index) => {
    if (index < 3) {
      values.push(item.toUpperCase())
    }
  })
  return values.join(', ')

}
const showModalHolidays = () => {
  if (holiday.value.holidaysOn7Days.length === 0 && holiday.value.employeeHolidays.length === 0 && holiday.value.holidaysToday.length === 0)
    showDialogHoliday.value = false;
  else showDialogHoliday.value = true;
}
// eslint-disable-next-line no-unused-vars
const sendMessage = () => {
  return true;
}
const gotToCalendar = () => {
  router.push('/calendar');
}
const getServerTime = async () => {
  getServerTimestamp().then((response) => {
    // console.log("SERVER TIME", respone)
    now.value = moment(response.data.serverTimestamp);
    // console.log("SERVER NOW", $now.format('YYYY-MM-DD'));
  });
}
const getNextHoliday = () => {
  HolidayService.getNextHoliday(user.value.id).then((resp) => {
    console.log("Vacaciones", resp.data.holidays);
    holiday.value = resp.data.holidays;
    console.log("Holidays", holiday.value);
    restHoliday.value = resp.data.holidays.employeeHolidays.daysLeftUntilHolidays;
    // restHoliday.value = resp.data.holidays.employeeHolidays.daysLeft;
    daysLeftUntilHolidays.value = resp.data.holidays.employeeHolidays.daysLeftUntilHolidays;
    daysLeftOnVacation.value = resp.data.holidays.employeeHolidays.daysLeftOnVacation;
    nextHoliday.value = resp.data.holidays && resp.data.holidays.holidaysOn7Days && resp.data.holidays.holidaysOn7Days.length > 0 &&
        resp.data.holidays.holidaysOn7Days
            .map((v) => v.name + " " + v.lastname)
            .join(', ');
    /* self.holiday = resp.data.holidays.holidaysToday
      .map((v) => v.name + " " + v.lastname)
      .join(); */
  });
}
const getNextPublicHolidays = () => {
  HolidayService.getNextPublicHolidays().then((resp) => {
    const today = now.value;
    const tomorrow = now.value.clone().add(1, 'days')
    if (resp.data.publicHolidays.length > 0) {
      resp.data.publicHolidays.forEach((publicHoliday) => {
        const datePublicHoliday = moment(publicHoliday.date);
        let string = '';
        if (publicHoliday.type === 'local') {
          string = ' EN ' + publicHoliday.workplaceProvince + ', ' + publicHoliday.workplaceLocation + ', ' + publicHoliday.name;
        } else {
          string = 'FIESTA NACIONAL, ' + publicHoliday.name;
        }
        if (today.format('YYYY-MM-DD') === datePublicHoliday.format('YYYY-MM-DD')) {
          festives.value.push("HOY " + string);
        } else if (tomorrow.format('YYYY-MM-DD') === datePublicHoliday.format('YYYY-MM-DD')) {
          const day = datePublicHoliday.format("D");
          moment.locale('es');
          const month = datePublicHoliday.locale('es').format("MMMM");
          festives.value.push("MAÑANA " + day + ' ' + month + ' ' + string);
        } else {
          const day = datePublicHoliday.format("D");
          moment.locale('es');
          const month = datePublicHoliday.locale('es').format("MMMM");
          festives.value.push("EL  " + day + ' ' + month + ' ' + string);
        }
      });
    }

  });
}
const getNextBirthDays = () => {
  const indexVisited = [];
  HolidayService.getNextBirthDays().then((resp) => {
    const manana = now.value.add(1, 'days');
    now.value.add(-1, 'days');
    // console.log("Cumple", resp.data);
    happyVerdes.value = resp.data.data;
    if (resp.data.data.length && resp.data.data.length > 0) {
      // console.log('SIIIII', self.now.format("YYYY-MM-DD"));
      const todays = resp.data.data.filter((v, index) => {
        if (v.birthday === now.value.format("YYYY-MM-DD")) {
          indexVisited.push(index);
          return true;
        }
      });
      // console.log('SIIIII', todays);
      happy_message.value += todays.map(v => v.name).join(', ');
      const you = todays.filter(v => v.email === user.value.email);
      if (you.length > 0) {
        birthDays.value.today = "ESTÁ ";
      }
      const others = todays.filter(v => v.email !== user.value.email);
      if (others.length > 0 && you.length > 0) {
        birthDays.value.today += 'Y ' + others.map(v => v.name).join(', ') + " ESTÁN DE CUMPLEAÑOS";
      }
      if (others.length > 0 && you.length === 0) {
        if (others.length > 1)
          birthDays.value.today = 'HOY ' + others.map(v => v.name).join(', ') + " ESTÁN DE CUMPLEAÑOS";
        else birthDays.value.today = 'HOY ' + others.map(v => v.name).join(', ') + " ESTÁ DE CUMPLEAÑOS";
      }
      const tomorrow = resp.data.data.filter((v, index) => {
        if (moment(v.birthday, 'YYYY-MM-DD') === manana && !indexVisited.includes(index)) {
          indexVisited.push(index);
          return true;
        }
      });
      const yout = tomorrow.filter(v => v.email === user.value.email);
      if (yout.length > 0) {
        birthDays.value.tomorrow = "MAÑANA ESTÁS";
      }
      const otherst = tomorrow.filter(v => v.email !== user.value.email);
      if (otherst.length > 0 && yout.length > 0) {
        birthDays.value.tomorrow += 'Y ' + otherst.map(v => v.name).join(', ') + " ESTÁN DE CUMPLEAÑOS";
      }
      if (otherst.length > 0 && yout.length === 0) {
        if (otherst.length > 1)
          birthDays.value.tomorrow = 'MAÑANA ' + otherst.map(v => v.name).join(', ') + " ESTÁN DE CUMPLEAÑOS";
        else birthDays.value.tomorrow = 'MAÑANA ' + otherst.map(v => v.name).join(', ') + " ESTÁS DE CUMPLEAÑOS";
      }
      const nexts = resp.data.data.filter((v, index) => !indexVisited.includes(index));
      if (nexts.length > 1) {
        birthDays.value.next = "LOS PRÓXIMOS CUMPLEAÑOS SON: " + nexts.map(v => v.day + ' ' + v.name).join(', ');
      } else {
        birthDays.value.next = "EL PRÓXIMO CUMPLEAÑOS ES EL DE: " + nexts.map(v => v.day + ' ' + v.name).join('');
      }
    } else
      birthDays.value = "";
  });
}
</script>


<style
    lang="scss"
    scoped>
@import "../assets/sass/styles.scss";


.el-dialog__body {
  background: #f3f0fb !important;
  min-height: 800px !important;
}

.close-icon {
  width: 8%;
  height: 20%;
}

.inter-icon {
  width: 2%;
  height: 2%;
}

#asterisk {
  position: absolute;
  vertical-align: super;
  height: auto;
  width: 2.2em;
  fill: #f9156f;
}

.el-dialog__header {
  padding: 0;
}

.my-header {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-header {
  text-align: left;
  font-size: 4em;
  font-weight: 900;
  letter-spacing: -0.05em;
  color: #f9156f;
}

#body-content {
  span#title {
    color: $font-blue-color;
    letter-spacing: -0.05em;
    font-size: 2.99em;
    font-weight: bolder;
  }

  #bell {
    fill: $bg-blue;
    width: 45px;
    margin-right: 10px;
    margin-bottom: 30px;
  }

  #chronometer {
    fill: #999;
    width: 12px;
  }

  #islands,
  #plane,
  #cursor {
    fill: $bg-blue;
    width: 25px;
  }

  .box-card {
    border-radius: 12px;
    color: $font-blue-color;
    margin-bottom: 15px;
  }

  .el-card.card-content {
    text-align: center;
  }

  .el-card__header {
    border-bottom: none;
    text-align: left;
    padding: 0px;
  }

  .el-card__body {
    .content-card {
      display: flex;
      padding: 0px;
      color: black;
    }

    .time {
      font-size: 12px;
      color: #999;
    }

    .card-text {
      font-size: 1.0em;
      color: black;
      font-weight: 503;
    }

    /* & div {
      justify-content: center;
      align-items: flex-end;
    } */
  }

  .btn-icon-plus {
    position: absolute;
    right: 10px;
    bottom: 10px;
    //width: 20px;
    //height: 20px;
    color: #f7d20e;
  }

  .home-cards-container {
    .card-icon {
      min-width: 220px;
      width: 20%;
      height: 100%;

      span {
        letter-spacing: -0.05em;
        font-size: 1.3em;
        font-weight: bolder;
      }

      .card-title {
        margin: 0 10px;
        letter-spacing: -0.05em;
        font-size: 1.5em;
      }
    }
  }
}

.btn-action {
  width: 200px;
  height: 40px;
}

</style>
