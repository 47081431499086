<template>
  <div>
    <HeaderTitle title="Sistemas" />
    <TicketForm
      v-if="ticket"
      action="create"
      :ticket="ticket"
      @saveData="createTicket"
    />
  </div>
</template>

<script setup>


import TicketForm from "@/views/tickets/TicketForm.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import TicketService from "@/api/services/ticket.service";
import router from "@/app/router/router";
import notification from '@/utils/plugins/NotificationService'
import {ref} from "vue";
import {useStore} from "vuex";
import {ElNotification} from "element-plus";
 const ticket = ref({
   "phone": '',
   "equipmentTag": '',
   "issue": '',
   "description": '',
   "ticketType": null,
   "ticketPriority": null,
   "department": null,
   "project": null,
   "createdAt": new Date(),
   "comments": [],
   "ticketDocuments": [],
   "requestingUser": null,
   "affectedUser": null,
   "status": null,
   "isByPhone": false,
   "isOnSite": false,
   "isProjectEntity": false
 })


   const store = useStore();
   const createTicket = (form) => {

      const formTicket = JSON.parse(JSON.stringify(form));
      formTicket.ticketType = formTicket.ticketType ? typeof formTicket.ticketType === 'object' ? formTicket.ticketType['@id'] : formTicket.ticketType : null;
      formTicket.ticketPriority = formTicket.ticketPriority ? formTicket.project === 'object' ?  formTicket.ticketPriority['@id'] : formTicket.ticketPriority : null;
      formTicket.department = formTicket.department ? formTicket.project === 'object' ?  formTicket.department['@id'] : formTicket.department :null;
      formTicket.project = formTicket.project ? typeof formTicket.project === 'object' ? formTicket.project['@id'] : formTicket.project : null;
      formTicket.affectedUser = formTicket.affectedUser ? typeof formTicket.project === 'object' ? formTicket.affectedUser['@id'] : formTicket.affectedUser : null;
      formTicket.requestingUser = formTicket.requestingUser ? typeof formTicket.project === 'object' ? formTicket.requestingUser['@id'] : formTicket.requestingUser : null;
      formTicket.assignedEmployee = formTicket.assignedEmployee ? typeof formTicket.project === 'object' ? formTicket.assignedEmployee['@id'] : formTicket.assignedEmployee : null;
      formTicket.requestingUser = formTicket.requestingUser ? typeof formTicket.requestingUser === 'object' ? formTicket.requestingUser['@id'] : formTicket.requestingUser : null;
      formTicket.employeeCreatingTicket = store.state.user['@id'];

      delete(formTicket.status)
      delete(formTicket.daysOpen)
      delete(formTicket.comments)
     if(formTicket.ticketDocuments.length > 0){
       formTicket.ticketDocuments = formTicket.ticketDocuments.map((doc)=>{
         return doc['@id'] ? doc['@id'] : doc
       })
     }
      TicketService.createTicket(formTicket).then((res)=>{
        if(res && (res.status === 200 || res.status === 201)){
          notification.addNotification("Datos guardados correctamente","success",10000)
          router.go(-1)
        }else{
          console.log(JSON.stringify(res, null, 2));
          ElNotification({
            title: 'Error',
            message: 'Ha ocurrido un error',
            type: 'error'
          })
        }

      }).catch((e)=>{
        console.log(JSON.stringify(e, null, 2));
        ElNotification({
          title: 'Error',
          message: 'Ha ocurrido un error',
          type: 'error'
        })
      })
    }

</script>

<style scoped>

</style>
