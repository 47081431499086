<template>
  <div class="container-fluid">
    <div class="w-100">
      <el-row :gutter="20">
        <el-col
          :span="6"
          class="d-flex align-items-center"
        >
          <img
            src="@/assets/icons/group.svg"
            alt=""
            class="mt-3"
          >
          <div class="title-text ml-4 mt-3">
            Tickets
          </div>
        </el-col>
        <el-col
          v-if="formModel"
          :span="18"
        >
          <div>
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="form-item">
                  <label for="project mr-2">Proyecto</label>
                  <el-select
                      placeholder="Proyecto"
                      :disabled="action !== 'create' && ((ticket.employeeCreatingTicket && store.state.user.id !== ticket.employeeCreatingTicket.id) || !ticket.employeeCreatingTicket)"
                      filterable
                      v-model="formModel.project">
                     <el-option
                      v-for="item in projects"
                      :key="item.id"
                      :label="item.name"
                      :value="item['@id']"
                    />
                  </el-select>
                </div>
                <p
                  v-if="errorList && errorList.project"
                  class="animated fadeIn invalid-feedback"
                >
                  {{ errorList.project }}
                </p>
              </el-col>
              <el-col
                v-if="action !== 'create'"
                :span="12"
              >
                <div class="form-item">
                  <label for="code">Código</label>
                  <el-input
                    disabled
                    :value="'['+formModel.tag+'] - '+formModel.id"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div
      v-if="formModel && formModel.project"
      class="card mb-5 wback"
      style="background-image: '@/assets/images/ticket-back.png';"
    >
      <div class="card-body">
        <el-row :gutter="20">
          <el-col
            v-if="action !== 'create' && formModel"
            :span="8"
          >
            <div class="form-item">
              <label for="state">Estado</label>
              <el-select
                  @change="changeState"
                  v-model="formModel.status"
                  filterable
                  :disabled="!store.getters.getUserLogged.roles.includes('ROLE_SYSTEMS')"
              >
                <el-option
                  v-for="item in statesEnabled"
                  :key="item.id"
                  :label="item.name"
                  :value="item['@id']"
                />
              </el-select>
            </div>
          </el-col>
          <el-col :span="16">
            <div class="form-item">
              <label for="issue">Asunto</label>
              <el-input
                v-model="formModel.issue" />
            </div>
            <p
                v-if="errorList && errorList.issue"
                class="animated fadeIn invalid-feedback"
            >
              {{ errorList.issue }}
            </p>
          </el-col>
          <el-col :span="8">
            <div class="form-item">
              <label for="typeTicket" class="w-100">Tipo</label>
              <el-select class="w-100" filterable v-model="formModel.ticketType" size="large">
                <el-option
                    v-for="item in types"
                    :key="item.id"
                    :label="item.name"
                    :value="item['@id']"
                />
              </el-select>
            </div>
            <p
              v-if="errorList && errorList.ticketType"
              class="animated fadeIn invalid-feedback"
            >
              {{ errorList.ticketType }}
            </p>
          </el-col>
          <el-col :span="8">
            <div class="form-item">
              <label for="user" class="w-100">Usuario  asignado</label>
              <el-select  class="w-100" filterable v-model="formModel.assignedEmployee">
                <el-option
                    v-for="item in users"
                    :key="item.id"
                    :label="item.fullName"
                    :value="item['@id']"
                />
              </el-select>
            </div>
          </el-col>
          <el-col
            v-if="props.action==='update'"
            :span="8"
          >
            <div style="margin-top: 2.8em;">
              <button
                class="btn btn_yellow btn-assign"
                @click="assign()"
              >
                ASIGNAR
              </button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>



    <el-row
      v-if="formModel && formModel.project"
      :gutter="10"
    >
      <el-col :lg="8">
        <div class="form-item">
          <label for="user" class="w-100">Solicitante</label>
          <el-select  class="w-100" filterable v-model="formModel.requestingUser">
            <el-option
                v-for="item in clientUsers"
                :key="item.id"
                :label="item.fullName"
                :value="item['@id']"
            />
          </el-select>
        </div>
        <p
          v-if="errorList && errorList.requestingUser"
          class="animated fadeIn invalid-feedback"
        >
          {{ errorList.requestingUser }}
        </p>
      </el-col>
      <el-col :lg="8">
        <div class="form-item">
          <label for="phone">Teléfono/Extensión</label>
          <el-input
            v-model="formModel.phone"
           type="text"
          />
          <p
              v-if="errorList && errorList.phone"
              class="animated fadeIn invalid-feedback"
          >
            {{ errorList.phone }}
          </p>
        </div>
      </el-col>
      <el-col  :lg="8">
        <el-row :gutter="10">
          <el-col :lg="12">
            <div class="form-item date-picker">
              <label for="createdAt">Fecha creación</label>
              <el-date-picker
                v-model="formModel.createdAt"
                type="date"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
                :picker-options="{ firstDayOfWeek: 1 }"
              />
            </div>
          </el-col>
          <el-col :lg="12">
            <div class="form-item date-picker">
              <label for="createdAt">Fecha cierre</label>
              <el-date-picker
                v-model="formModel.closingDate"
                disabled
                name="from-date"
                type="date"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
                :picker-options="{ firstDayOfWeek: 1 }"
              />
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8">
        <div class="form-item">
          <label for="userAffected" class="w-100">Usuario afectado</label>
          <el-select
              class="w-100"
              filterable
              v-model="formModel.affectedUser">
            <el-option
                v-for="item in clientUsers"
                :key="item.id"
                :label="item.name + ' ' + item.lastName"
                :value="item['@id']"
            />
          </el-select>
        </div>
        <p
          v-if="errorList && errorList.affectedUser"
          class="animated fadeIn invalid-feedback"
        >
          {{ errorList.affectedUser }}
        </p>
      </el-col>
      <el-col :span="8">
        <div class="form-item">
          <label for="department" class="w-100">Departamento</label>
          <el-select class="w-100"
                     filterable
                     v-model="formModel.department">
            <el-option
                v-for="item in optionsDepartment"
                :key="item.id"
                :label="item.name"
                :value="item['@id']"
            />
          </el-select>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="form-item">
          <label for="equipmentTag">Etiqueta del equipo</label>
          <el-input
              type="text"
            v-model="formModel.equipmentTag"
          />
        </div>
      </el-col>
      <el-col :span="8">
        <div class="form-item">
          <label for="affectedUserEmail">Email usuario afectado</label>
          <el-input
              disabled
              type="text"
              v-model="formModel.affectedUserEmail"
          />
        </div>
        <p
          v-if="errorList && errorList.affectedUser && errorList.affectedUserEmail"
          class="animated fadeIn invalid-feedback"
        >
          {{ errorList.affectedUserEmail }}
        </p>
      </el-col>
      <el-col :span="8">
        <div class="form-item">
          <label for="requestingUserEmail">Email Solicitante</label>
          <el-input
              disabled
              type="text"
              v-model="formModel.requestingUserEmail"
          />
        </div>
        <p
          v-if="errorList && errorList.requestingUser && errorList.requestingUserEmail"
          class="animated fadeIn invalid-feedback"
        >
          {{ errorList.requestingUserEmail }}
        </p>
      </el-col>
      <el-col :span="8">
        <ClockifyButton v-if="action !== 'create'"
          link=""
          :ticket="ticket"
          :clockifyProjectId="formModel.clockifyProjectId"
          :clockify-tag-id="formModel.clockifyTagId"
          @update-ticket="updateTicket"
        />
      </el-col>
    </el-row>
    <el-row v-if="formModel && formModel.project">
      <el-col :span="24">
        <div class="form-item">
          <label for="description">Descripción</label>
          <el-input
              v-model="formModel.description"
              :autosize="{ minRows: 8 }"
              type="textarea"
          />
        </div>
        <p
          v-if="errorList && errorList.description"
          class="animated fadeIn invalid-feedback"
        >
          {{ errorList.description }}
        </p>
      </el-col>
    </el-row>
    <el-row
      v-if="formModel && formModel.project"
      :gutter="20"
    >
      <el-col :span="12">
        <div class="form-item">
          <label>Adjuntos</label>
          <div
            class="border bg-white h-auto p-2 pl-5"
            style="border-radius: 16px;"
          >
            <span
              v-if="ticketDocument && ticketDocument.name"
              class="pointer text-primary"
              @click="showFile(ticketDocument)"
            >{{
              ticketDocument.name
            }}</span>
            <p v-else>
              No tiene adjuntos asociados
            </p>
          </div>
          <button
            class="btn btn_yellow btn-assign mt-2"
            @click="selectFile"
          >
            Seleccione...
          </button>
        </div>
      </el-col>
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="form-item">
              <label for="priority" class="w-100">Prioridad</label>
              <el-select size="large" filterable v-model="formModel.ticketPriority">
                <el-option
                    v-for="item in priorities"
                    :key="item.id"
                    :label="item.name"
                    :value="item['@id']"
                />
              </el-select>
              <p
                v-if="errorList && errorList.ticketPriority"
                class="animated fadeIn invalid-feedback"
              >
                {{ errorList.ticketPriority }}
              </p>
            </div>
          </el-col>
          <div class="pl-2">
            <el-col :span="24">
              <input
                v-show="formModel.isByPhone"
                id="isByPhone"
                type="radio"
                class="align-self-center radio-larg"
                name="isByPhone"
                checked
                @click.prevent="formModel.isByPhone = !formModel.isByPhone"
              >
              <input
                v-show="!formModel.isByPhone"
                id="isByPhone"
                type="radio"
                class="align-self-center radio-larg"
                name="isByPhone"
                @click.prevent="formModel.isByPhone = !formModel.isByPhone"
              >
              <label
                for="isByPhone"
                class="ml-2"
              > Llamada telefónica</label>
            </el-col>
            <el-col :span="24">
              <input
                v-show="formModel.isOnSite"
                id="isOnSite"
                type="radio"
                class="align-self-center radio-larg"
                name="isOnSite"
                checked
                @click.prevent="formModel.isOnSite = !formModel.isOnSite"
              >
              <input
                v-show="!formModel.isOnSite"
                id="isOnSite"
                type="radio"
                class="align-self-center radio-larg"
                name="isOnSite"
                @click.prevent="formModel.isOnSite = !formModel.isOnSite"
              >
              <label
                for="isOnSite"
                class="ml-2"
              >Resuelto in situ</label>
            </el-col>
            <el-col :span="24">
              <input
                v-show="formModel.isProjectEntity"
                id="isProjectEntity"
                type="radio"
                class="align-self-center radio-larg"
                name="isProjectEntity"
                checked
                @click.prevent="formModel.isProjectEntity = !formModel.isProjectEntity"
              >
              <input
                v-show="!formModel.isProjectEntity"
                id="isProjectEntity"
                type="radio"
                class="align-self-center radio-larg"
                name="isProjectEntity"
                @click.prevent="formModel.isProjectEntity = !formModel.isProjectEntity"
              >
              <label
                for="isProjectEntity"
                class="ml-2"
              >  Entidad proyecto</label>
            </el-col>
          </div>
        </el-row>
      </el-col>
    </el-row>
    <el-row
      v-if="formModel && action !== 'create'  && formModel.project"
      :gutter="20"
    >
      <el-col :span="12">
        <SharedTicketComments
          title="Comentarios privados"
          :ticket="ticket"
          :is-private="true"
          placeholder="Escriba un comentario"
        />
      </el-col>
      <el-col :span="12">
        <SharedTicketComments
          title="Comentarios públicos"
          :ticket="ticket"
          :is-private="false"
          placeholder="Escriba un comentario"
        />
      </el-col>
    </el-row>
    <el-row v-if="formModel.project">
      <div class="d-flex justify-content-end align-items-center mt-lg-5">
        <button
          class="btn btn_yellow search mr-5"
          @click="router.go(-1)"
        >
          VOLVER
        </button>
        <button
          class="btn btn_darkpink search"
          @click.prevent="submit"
        >
          GUARDAR
        </button>
      </div>
    </el-row>
  </div>
</template>

<script setup>

import {useStore} from "vuex";
import ProjectsService from "@/api/services/projects.service";
import TicketStatusService from "@/api/services/ticket.status.service";
import UsuarioExtranetService from "@/api/services/usersExtranet";
import EmployeeService from "@/api/services/employee.service";
import TicketPriorityService from "@/api/services/ticket.priority.service";
import TicketTypeService from "@/api/services/ticket.type.service";
import TicketService from "@/api/services/ticket.service";
import SharedTicketComments from "@/components/shared/SharedTicketComments.vue";
import DepartmentService from "@/api/services/department.service";
import ClockifyButton from "@/components/shared/ClockifyButton.vue";
import { ElMessageBox} from "element-plus";
import {useRouter} from "vue-router";
import {computed, onMounted, ref, defineProps, defineEmits, watch} from "vue";
import {ElNotification} from "element-plus";
 import TicketStatusHistoryService from "@/api/services/ticket.status.history.service";
import moment from "moment/moment";

const store = useStore();
const router = useRouter();

const props = defineProps({
  action: {
    type: String,
    required: true
  },
  ticket: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['getTicket']);
const errorList = ref({});
// eslint-disable-next-line no-unused-vars
const params = ref({});
const formModel = ref(props.ticket || {
  project: null,
  status: null,
  department: null,
});
const users = ref([]);
const clientUsers = ref([]);
const types = ref([]);
const projects = ref([]);
const states = ref([]);
const priorities = ref([]);
const optionsAffectedUser = ref([]);
const optionsRegisterUser = ref([]);
const optionsDepartment = ref([]);
// eslint-disable-next-line no-unused-vars
const statusSelected = ref(null);
const ticketDocument = ref(null);
const projectSelected = ref(null);
// eslint-disable-next-line no-unused-vars
const errors = ref(null);
// eslint-disable-next-line no-unused-vars
const user = computed(() => store.state.user);
// eslint-disable-next-line no-unused-vars
const userAffected = computed(() => {
  return formModel.value &&
      formModel.value.affectedUser &&
      formModel.value.affectedUser.name+" "+formModel.value.affectedUser.lastName;
});
const statesEnabled = computed(() => {
  if(props.ticket && props.ticket.status && typeof props.ticket.status === "object" && props.ticket.status.name === "Cerrado"){
    return states.value.filter(state => state.name === "Reabierto" || state.name === "Cerrado");
  }else{
    if(props.ticket.status &&typeof props.ticket.status === "string" ){
      let closed =  states.value.find(state => state['@id'] === props.ticket.status);
      if(closed && closed.name === 'Cerrado'){
        return states.value.filter(state => state.name === "Reabierto"|| state.name === "Cerrado");
      }
    }
  }
  return states.value;
});
// eslint-disable-next-line no-unused-vars
const userRegister = computed(() => {
  return formModel.value &&
      formModel.value.requestingUser &&
      formModel.value.requestingUser.name+" "+formModel.value.requestingUser.lastName;
});

watch(
    () => formModel.value.project,
    async (newValue) => {
      if (newValue && projects.value.length > 0) {
        let filteredProject = projects.value.find(item => item['@id'] === newValue)
        if (filteredProject && filteredProject.client) {
          await getClientUsers(filteredProject.client.id)
          await getDepartmentsByClient(filteredProject.client.id)
        }
      }
    }
);
watch(
    () => formModel.value.requestingUser,
    (newValue) => {
      if(newValue ){
        let filteredUser = clientUsers.value.find(item => item['@id'] === newValue)
        if(filteredUser){
          formModel.value.requestingUserEmail = filteredUser.email
          if(!formModel.value.affectedUser){
            formModel.value.affectedUser = filteredUser['@id']
            formModel.value.affectedUserEmail = filteredUser.email
          }
        }

      }
    }
);
watch(
    () => formModel.value.affectedUser,
    (newValue) => {
      if(newValue ){
        let filteredUser = clientUsers.value.find(item => item['@id'] === newValue)
        if(filteredUser){
          formModel.value.affectedUserEmail = filteredUser.email
        }

      }
    }
);


  onMounted(async() =>{
   await store.dispatch('setLoading', true)
    await Promise.all([
        getProjects(),
        getStates(),
        getPriorities(),
        getUsers(),
        getTypes(),
        getUsersExtranet()
    ]).then(async() => {
      if (props.action === "update"){
        if(formModel.value && formModel.value.ticketDocuments && formModel.value.ticketDocuments.length>0)
          await getTicketDocument(formModel.value.ticketDocuments[0]["@id"]);


        let onlyStatus = props.ticket.ticketStatusHistories.find(tsh => {
          if (!tsh.dateEnd) {
            return tsh.status['@id']
          }
        })

        if (!onlyStatus) {
          onlyStatus = props.ticket.ticketStatusHistories.find(tsh => {
            if (tsh.dateStart && tsh.dateEnd && tsh.status.name === "Cerrado") {
              return tsh.status['@id']
            }
          })
        }

        formModel.value.status = onlyStatus.status['@id']
        if(typeof formModel.value.ticketPriority === "object"){
          formModel.value.ticketPriority = formModel.value.ticketPriority['@id'];
        }
        if(typeof formModel.value.ticketType === "object"){
          formModel.value.clockifyTagId = formModel.value.ticketType.clockifyTagId;
          formModel.value.ticketType = formModel.value.ticketType['@id'];

        }
        if(formModel.value.assignedEmployee){
          formModel.value.assignedEmployee = typeof formModel.value.assignedEmployee === "object" ? formModel.value.assignedEmployee['@id'] :formModel.value.assignedEmployee;
        }
        if(props.ticket.affectedUser){
          formModel.value.affectedUserEmail = typeof props.ticket.affectedUser === "object"  ? props.ticket.affectedUser.email : null
          formModel.value.affectedUser = typeof props.ticket.affectedUser === "object" ? props.ticket.affectedUser['@id'] :props.ticket.affectedUser;
        }
        if(typeof formModel.value.requestingUser === "object"){
          formModel.value.requestingUserEmail = formModel.value.requestingUser.email
          formModel.value.requestingUser = formModel.value.requestingUser['@id'];

        }
        if(typeof formModel.value.project === "object"){
          formModel.value.clockifyProjectId = formModel.value.project.clockifyProjectId;
          // formModel.value.project = formModel.value.project['@id'];
        }

      } else {
        let now = moment(new Date());
        formModel.value.project = projects.value[0];
        formModel.value.createdAt = now.format("YYYY-MM-DD HH:mm:ss")
        formModel.value.status = states.value.find(v=>v.name === "Abierto");
        formModel.value.ticketPriority = priorities.value.find(v=>v.name === "Alta")['@id'];
      }

    });
    projectSelected.value = typeof formModel.value.project === "object" ? formModel.value.project : projects.value.find(v=>v["@id"] === formModel.value.project);

    if(projectSelected.value && projectSelected.value.client?.id){
      await getClientUsers(projectSelected.value.client.id)
      await getDepartmentsByClient(projectSelected.value.client.id)
    }
    await store.dispatch('setLoading', false)
  })

    const updateTicket = async() => {
       emits('getTicket')
    }
    const cleanMedia = async() => {
      await TicketService.deleteMediaTicket(ticketDocument.value.id);
      ticketDocument.value = null;
      formModel.value.ticketDocument = null;
      return true;
    }
    const confirmFileAttached = async() => {

      return ElMessageBox.confirm(
          '¿Deseas borrar el archivo?',
          'Confirmar',
          {
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            type: 'warning',
          }
      )
          .then(async () => {
           await store.dispatch('setLoading', true)
            await cleanMedia();
           await store.dispatch('setLoading', false)
        //    notification.addNotification("Archivo borrado correctamente","success")
            return true;
          })
          .catch(() => {
         //   notification.addNotification("Ah cancelado, se mantiene el archivo adjunto actual","info")

            return false;
          });
    }
    const selectFile = async() => {
      //console.log("ARCHIVO",ticketDocument);
      if (ticketDocument.value && ticketDocument.value.name) {
        const result = await confirmFileAttached();
        if (!result) return;
      }
      const fileSelector = document.createElement("input");
      fileSelector.setAttribute("type", "file");
      fileSelector.addEventListener("change", async function (e) {
        /*  var extn = e.target.files[0].type.split("/")[1];*/


        let size = e.target.files[0].size;
        let maxSize = 2097152; // 2097152 byte
        if (size < maxSize) {
         await store.dispatch('setLoading', true)
          const response = await TicketService.createMediaTicket(e.target.files[0]);
          const media = response.data;

          formModel.value.ticketDocuments.push(media["@id"]);
          ticketDocument.value = media;
         await store.dispatch('setLoading', false)
          ElNotification({
            type:'success',
            message:'Archivo cargado correctamente.',
            duration:10000
          })
        //  notification.addNotification("Archivo cargado correctamente");
        } else {
         await store.dispatch('setLoading', false)
          ElNotification({
            type:'error',
            message:'No se puede cargar el Archivo porque excede el límite permitido.',
            duration:10000
          })
          await store.dispatch('setLoading', false)
        }
        /*  _urlImage =URL.createObjectURL(e.target.files[0]); */
      });
      fileSelector.click();
      return false;
    }
// eslint-disable-next-line no-unused-vars

    const getDepartmentsByClient = async(clienId) => {
    try{
      const response = await  DepartmentService.getDepartmentsByClient(clienId);
      optionsDepartment.value = response.data["hydra:member"];
    }catch (e){
      console.log(e)
    }

    }
    const getUsersExtranet = async () => {
      let clientId = null
      if (formModel.value && formModel.value.project && formModel.value.project.client) {
        clientId = formModel.value.project.client.id
      }

      const params = {
        id: clientId,
        itemsPerPage: 999999,
        page: 1
      }
      const response = await UsuarioExtranetService.getClientUsers(params, clientId);

      clientUsers.value = response.data["hydra:member"];

      return response.data["hydra:member"];
    }
// eslint-disable-next-line no-unused-vars
    const getRegisterUsers = async(obj) => {

      optionsRegisterUser.value = await getUsersExtranet(obj);
    }
// eslint-disable-next-line no-unused-vars
    const getUsersAffected = async(obj) => {
      optionsAffectedUser.value = await  getUsersExtranet(obj);
    }
    const getTicketDocument = async(url) => {
      const response = await TicketService.getTicketDocument(url);
      ticketDocument.value = response.data;

      return response.data["hydra:member"];
    }
    const showFile = (doc) => {
      if(doc.contentUrl){
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = process.env.VUE_APP_BASE_URI+"/" + doc.contentUrl;
        link.click();
      }

    }
    const getProjects = async() => {
      let params = {
        archived: false,
        itemsPerPage:99999,
        page:1,
        name: 'sistemas etsa',
      'exists[client.extranetUsers]': true
      }
      const response = await ProjectsService.getProjects(params);
      projects.value = response.data["hydra:member"];
      return response.data["hydra:member"];
    }
    const getStates = async() => {
      const response = await TicketStatusService.getStates();
      states.value = response.data["hydra:member"];
      return response.data["hydra:member"];
    }
    const getTypes = async() => {
      const response = await TicketTypeService.getTypes();
      types.value = response.data["hydra:member"];
    }
// eslint-disable-next-line no-unused-vars
    const getClientUsers = async (id) => {
      let clientId = null
      if (id.toString().includes('/')) {
        clientId = id.split('/')
        clientId = clientId[clientId.length -1]
      } else {
        clientId = id
      }

      const params = {
        itemsPerPage: 999999,
        page: 1
      }
      const response = await UsuarioExtranetService.getClientUsers(params, clientId);

      clientUsers.value = response.data["hydra:member"];
      return response.data["hydra:member"];
    }
    const getUsers = async () => {
      const response = await EmployeeService.getSystemEmployees();
      users.value = response.data["hydra:member"];
      return response.data["hydra:member"];
    }
    const getPriorities = async () => {
          const response = await TicketPriorityService.getPriorities();
          priorities.value = response.data["hydra:member"];
          return response.data["hydra:member"];
    }
    const changeState = () => {
      ElMessageBox.alert(  'Vas a cambiar el estado del ticket, ¿Estás seguro?', '', {
        confirmButtonText: 'OK',
        callback: () => {
          TicketStatusHistoryService.createStatusHistory({status:formModel.value.status, ticket: props.ticket['@id']})
              .then((res) => {
                formModel.value.status = res.data.status;
                ElNotification({
                  type:'success',
                  message:'Estado cambiado correctamente',
                  duration:10000
                })
              })
              .catch(() => {

              })
        },
      })
    }
    const assign = () => {
      if(!formModel.value.assignedEmployee){
        ElNotification({
          type:'error',
          message:'Debe seleccionar un usuario',
          duration:10000
        })
        //notification.addNotification("Debe seleccionar un usuario","error",10000)
        return
      }
      TicketService.updateTicket({assignedEmployee:typeof formModel.value.assignedEmployee == 'object' ? formModel.value.assignedEmployee['@id'] :formModel.value.assignedEmployee}, props.ticket.id)
          .then(()=>{
            ElNotification({
              type:'success',
              message:'Usuario asignado correctamente',
              duration:10000
            })
           // notification.addNotification("Usuario asignado correctamente","success",10000)
          })
    }
    const submit = () => {
      errorList.value = {}
      let valid = true
      if(!formModel.value.project){
        errorList.value.project = "El campo proyecto es requerido";
        valid= false
      }
      if(!formModel.value.ticketType){
        errorList.value.ticketType = "El campo tipo es requerido";
        valid= false
      }
      if(!formModel.value.ticketPriority){
        errorList.value.ticketPriority = "El campo prioridad es requerido";
        valid= false
      }
      if(!formModel.value.description){
        errorList.value.description = "El campo descripción es requerido";
        valid= false
      }
      if(!formModel.value.affectedUserEmail){
        errorList.value.affectedUserEmail = "El email del usuario afectado es requerido";
        valid= false
      }
      if(!formModel.value.affectedUser){
        errorList.value.affectedUser = "El usuario afectado es requerido";
        valid= false
      }
      if(!formModel.value.requestingUser){
        errorList.value.requestingUser = "El usuario solicitante es requerido";
        errorList.value.requestingUserEmail = "El email del usuario solicitante es requerido";
        valid= false
      }

      if(!formModel.value.phone){
        errorList.value.phone = "El teléfono  es requeridos";
        valid= false
      }
      if(formModel.value.phone.length !== 9){
        errorList.value.phone = "El teléfono debe tener 9 dígitos";
        valid= false
      }
      if(!formModel.value.issue){
        errorList.value.issue = "El asunto es requerido";
        valid= false
      }


     if (!valid) {
      ElNotification({
        type:'error',
        message:'No se pudo completar el proceso, ha de rellenar todos los campos marcados y requeridos'
      })
      }else{
       emits('saveData',formModel.value)
     }

    }



</script>

<style scoped lang="scss">
.btn-light {
  height: 42px!important;
}
.btn-assign{
  height: 42px;
  color: #474CE6;
  min-width: 200px;
  font-weight: 400;
  font-size: 20px;
  width: 300px;
  font-family: "Montserrat";
}
.search {
  height: 42px;
  width: 300px;
}
.wback {
  background-image: url('../../assets/images/ticket-back.png');
  background-repeat: no-repeat;
  background-size: contain;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-top: 8px;
  font-family: Montserrat;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
}
label.checkbox-label input[type=checkbox] {
  position: relative;
  vertical-align: middle;
  bottom: 1px;
}
</style>
